import React, { FC } from "react";
import { Operation } from "src/api/interfaces/permissions";
import { KatDropdownButton } from "@amzn/katal-react";
import { usePermissions } from "src/components/Contexts/PermissionsContext";
import { useCountrySelection } from "src/components/Contexts/CountrySelectionContext";
import { checkPermissionOnCountry } from "src/components/AICLayout/AICHeader/Permissions/PermissionCheckers";
import { WithTranslation, withTranslation } from "react-i18next";
import { ConsolidatedDocument } from "src/api/interfaces/document_listing";
import { DataTableSelectionProps } from "src/components/DataTable/DataTableDefinition";
import BulkOperationsConfirmationModal from "src/components/Pages/DocumentList/BulkOperations/BulkOperationsConfirmationModal";
import { InboundStatus } from "src/api/interfaces/inbound_document";
import { BulkOperationsActions } from "src/api/interfaces/bulk_operations";
import { useDocumentList } from "src/components/Contexts/DocumentList/Provider";
import { DocumentRow } from "src/components/Contexts/DocumentList/types";

const ACTIONS_DROPDOWN_LABEL = "aic_doc_page_user_action_dropdown_actions";
const NOT_SELECTED = "NOT_SELECTED";
const USE_CASE_SEPARATOR = ",";

export const actionTranslation: Record<BulkOperationsActions, string> = {
  [BulkOperationsActions.Revalidate]:
    "aic_internal_bulk_operations_dropdown_action_revalidate",
  [BulkOperationsActions.ForceAccept]:
    "aic_internal_bulk_operations_dropdown_action_force_accept",
  [BulkOperationsActions.ForceReject]:
    "aic_internal_bulk_operations_dropdown_action_force_reject",
};

const actionPermissionMapping: Record<BulkOperationsActions, Operation> = {
  [BulkOperationsActions.Revalidate]: Operation.PerformComplianceUserActions,
  [BulkOperationsActions.ForceAccept]: Operation.PerformCriticalActions,
  [BulkOperationsActions.ForceReject]: Operation.PerformCriticalActions,
};

export const bulkOperationRowSelectionEvaluator = (
  doc: DocumentRow & DataTableSelectionProps,
  selectionList: Array<ConsolidatedDocument & DataTableSelectionProps>
): boolean => {
  const selected = selectionList.filter((item) => {
    return item.selected;
  });

  return (
    (!selected ||
      selected.every((item) => {
        return (
          doc.documentType === item.documentType &&
          doc.countryCode === item.countryCode
        );
      })) &&
    doc.status === InboundStatus.PENDING_ACTIONS
  );
};

export const getBulkOperationsAllowedActions = (
  permissions: Map<string, Map<string, Operation[]>>,
  countryCode?: string
): BulkOperationsActions[] => {
  return [
    BulkOperationsActions.Revalidate,
    BulkOperationsActions.ForceAccept,
    BulkOperationsActions.ForceReject,
  ].filter((key) => {
    return checkPermissionOnCountry(
      permissions,
      countryCode,
      actionPermissionMapping[key]
    );
  });
};

const BulkOperations: FC<WithTranslation> = ({ t }) => {
  const { permissions } = usePermissions();
  const { selectedCountry } = useCountrySelection();
  const hasBOPermission = checkPermissionOnCountry(
    permissions,
    selectedCountry?.countryCode,
    Operation.PerformBulkOperations
  );
  const {
    state: { documents },
  } = useDocumentList();

  const [handlerModalAction, setHandlerModalAction] =
    React.useState<string>("");

  const action = (event: KatDropdownButton.ActionEvent) => {
    setHandlerModalAction(event.detail.action);
  };

  const selectedDocuments = Object.values(documents).filter(
    (document: DocumentRow) => document.selected
  );
  const useCasesSet = new Set();
  selectedDocuments.forEach((document: DocumentRow) =>
    useCasesSet.add(document.useCase)
  );
  const useCases = Array.from(useCasesSet).join(USE_CASE_SEPARATOR);

  const documentIds = selectedDocuments.map(
    (document: DocumentRow) => document.documentId
  );
  const countryCode = selectedCountry && selectedCountry.countryCode;
  const { documentType } = selectedDocuments.length
    ? selectedDocuments[0]
    : { documentType: NOT_SELECTED };

  const allowedActions = getBulkOperationsAllowedActions(
    permissions,
    selectedCountry?.countryCode
  );

  return hasBOPermission && !!allowedActions.length ? (
    <div>
      <BulkOperationsConfirmationModal
        documentList={documentIds}
        documentType={documentType}
        useCases={useCases}
        countryCode={countryCode || NOT_SELECTED}
        action={handlerModalAction}
        setHandlerModalAction={setHandlerModalAction}
      />
      <KatDropdownButton
        id="bulk-operations-user-actions-dropdown"
        variant="primary"
        size="base"
        disabled={!selectedDocuments.length}
        options={[
          { label: t(ACTIONS_DROPDOWN_LABEL) },
          ...allowedActions.map((key) => {
            return {
              label: t(actionTranslation[key]),
              action: key,
            };
          }),
        ]}
        onAction={action}
      />
    </div>
  ) : (
    <div />
  );
};

export default withTranslation()(BulkOperations);
