import React, { FC } from "react";
import { DocumentField } from "src/constants";

import classnames from "classnames";
import { DataTableCellChildComponentProps } from "src/components/DataTable/DataTableDefinition";
import { ConsolidatedDocument } from "src/api/interfaces/document_listing";
import { ExternalPartner } from "src/api/interfaces/inbound_document";
import styles from "../../../../styles.module.scss";

const EXTERNAL_PARTNER_MAX_SIZE = 25;

interface GetPartnerResponse {
  title?: string;
  text: string;
}

const fixMaxSize = (response: GetPartnerResponse) => {
  if (response.text.length > EXTERNAL_PARTNER_MAX_SIZE) {
    if (response.title === undefined) {
      response.title = response.text;
    }

    response.text = response.text
      .slice(0, EXTERNAL_PARTNER_MAX_SIZE)
      .concat("...");
  }
  return response;
};

const getExternalPartner = (consolidatedDocument: ConsolidatedDocument) => {
  const response: GetPartnerResponse = {
    text: "",
  };

  const partner = consolidatedDocument[DocumentField.EXTERNAL_PARTNER];

  const { partnerType, partnerCode } = partner;
  response.text = `${partnerType}: ${partnerCode}`;

  return fixMaxSize(response);
};

const getExternalPartners: (
  consolidatedDocument: ConsolidatedDocument
) => GetPartnerResponse = (consolidatedDocument: ConsolidatedDocument) => {
  const response: GetPartnerResponse = {
    text: "",
  };

  const partners = consolidatedDocument[DocumentField.EXTERNAL_PARTNERS];

  const { partnerType, parentPartnerCode } = partners[0];
  response.text = `${partnerType}: ${parentPartnerCode}`;

  if (partners.length > 1) {
    response.text = response.text.concat(", ...");
    const partnersMap: { [key: string]: string[] } = {};
    partners.forEach((p: ExternalPartner) => {
      if (p.partnerType in partnersMap) {
        partnersMap[p.partnerType].push(p.partnerCode);
      } else {
        partnersMap[p.partnerType] = [p.partnerCode];
      }
    });
    let titles = "";
    Object.entries(partnersMap).forEach(([pCode, pList]) => {
      const capitalizedCode = pCode.charAt(0).toUpperCase() + pCode.slice(1);
      titles = titles.concat(`${capitalizedCode}s:\n${pList.join(", ")}\n`);
    });
    response.title = titles;
  }
  return fixMaxSize(response);
};

export const getPartner: (
  consolidatedDocument: ConsolidatedDocument
) => GetPartnerResponse | null = (
  consolidatedDocument: ConsolidatedDocument
) => {
  if (
    DocumentField.EXTERNAL_PARTNERS in consolidatedDocument &&
    consolidatedDocument[DocumentField.EXTERNAL_PARTNERS] &&
    consolidatedDocument[DocumentField.EXTERNAL_PARTNERS].length > 0
  ) {
    return getExternalPartners(consolidatedDocument);
  }
  if (
    DocumentField.EXTERNAL_PARTNER in consolidatedDocument &&
    consolidatedDocument[DocumentField.EXTERNAL_PARTNER] !== null
  ) {
    return getExternalPartner(consolidatedDocument);
  }
  return null;
};

const DocumentExternalPartner: FC<
  DataTableCellChildComponentProps<ConsolidatedDocument>
> = ({ content }) => {
  const externalPartner = getPartner(content);

  return (
    <p
      className={classnames(styles.capitalizedText)}
      title={externalPartner?.title}
    >
      {externalPartner?.text}
    </p>
  );
};

export default DocumentExternalPartner;
