import {
  ColumnDefinition,
  DataTableDefinition,
} from "src/components/DataTable/DataTableDefinition";
import { InboundDocument } from "src/api/interfaces/inbound_document";
import { DocumentRelationshipField, InboundDocumentField } from "src/constants";
import { documentColumnsDefinition } from "src/components/Pages/DocumentInbound/Tabs/Documents/RelatedDocumentsListing/document-data-table-definition";
import styles from "src/styles.module.scss";

const [documentId, documentType, ...remainingColumnsDefinitions] =
  documentColumnsDefinition;

const relatedDocumentColumnsDefinition: ColumnDefinition[] = [
  documentId,
  {
    label: "aic_doc_page_documents_tab_header_relationship",
    property: DocumentRelationshipField.RELATIONSHIP_TYPE,
    translateValue: true,
  },
  documentType,
  {
    label: "aic_doc_page_documents_tab_header_doc_subtype",
    property: InboundDocumentField.DOCUMENT_SUBTYPE.valueOf(),
    valueStyle: [styles.capitalizedText],
  },
  {
    label: "aic_doc_page_documents_tab_header_doc_use_case",
    property: InboundDocumentField.USE_CASE.valueOf(),
    valueStyle: [styles.capitalizedText],
  },
  ...remainingColumnsDefinitions,
];

const relatedDocumentDataTableDefinition: DataTableDefinition<InboundDocument> =
  {
    columnsDefinition: relatedDocumentColumnsDefinition,
  };

export default relatedDocumentDataTableDefinition;
