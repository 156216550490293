import React, { FC, useState } from "react";
import { DataTableCellChildComponentProps } from "src/components/DataTable/DataTableDefinition";
import { useTranslation } from "react-i18next";
import { getTranslatedValue } from "src/utils";
import classnames from "classnames";

const SEE_MORE = "See more";
const SEE_LESS = "See less";
const COLLAPSE_SIZE = 100;
const SPACE = " ";

const DataTableExpandableText: FC<DataTableExpandableTextProps> = ({
  content,
  valueStyle,
}) => {
  const { t } = useTranslation();
  const text = content.details?.valueOf() ?? "-";

  const [seeMore, setSeeMore] = useState(true);
  const enableCollapse = text.length > COLLAPSE_SIZE;

  const nearestSpace = text
    .substring(0, COLLAPSE_SIZE)
    .lastIndexOf(SPACE, COLLAPSE_SIZE);
  const croppedText = text.substring(
    0,
    nearestSpace < 0 ? COLLAPSE_SIZE : nearestSpace
  );

  const seeMoreSeeLessLink = (
    <a onClick={() => setSeeMore(!seeMore)} role="presentation">
      {seeMore
        ? getTranslatedValue(SEE_MORE, t)
        : getTranslatedValue(SEE_LESS, t)}
    </a>
  );

  return enableCollapse ? (
    <span className={classnames(valueStyle)}>
      {seeMore ? croppedText : text}&nbsp;{seeMoreSeeLessLink}
    </span>
  ) : (
    <span className={classnames(valueStyle)}>{text}</span>
  );
};

export type DataTableExpandableTextProps =
  DataTableCellChildComponentProps<any>;

export default DataTableExpandableText;
